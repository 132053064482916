.wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 1rem;
  font-size: 1.6rem;
  margin: 1rem 0;
}