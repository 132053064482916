.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.size-wrapper {
  width: 80%;
}

.ratio {
  width: 100%;
  padding-bottom: 56.25%;
  border: 2px solid red;
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.wattermark {
  position: absolute;
  top: 0;
  right: 0;
}