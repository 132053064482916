.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 1s all;
  padding: 2rem;
  font-family: 'Open-Sans', sans-serif;
  font-weight: 600;
  color: white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  height: 100%;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.france {
  height: 45%;
}

.europe {
  height: 45%;
  margin-top: 1rem;
}

.img {
  height: 100%;
}

.title {
  width: 80%;
  text-align: center;
  margin-bottom: 1rem;
  margin: auto;
  font-size: 2.4rem;
  text-transform: capitalize;
}