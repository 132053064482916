// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  font-size: 62.5%; // 10px
  box-sizing: border-box;
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 1.6rem;
  padding: 0;
  background-color: #fff;
}

#root {
  height: 100vh;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a,
a:visited,
a:focus {
  text-decoration: none;
}